import { computed } from 'vue'
import type { User } from '@workos-inc/node'
import { useNuxtApp } from '#app'

export function useAuth() {
  const { $auth } = useNuxtApp()
  const isChecking = useState('isChecking', () => true)
  const runtimeConfig = useRuntimeConfig()
  const { apiBase: api } = runtimeConfig.public

  const login = (redirect?: string) => {
    if (import.meta.client && (!redirect || typeof redirect !== 'string')) {
      const route = useRoute()
      redirect = route.fullPath
    }
    const _redirect = encodeURI(redirect ?? '/')
    window.location.href = `${api}/auth/login?state=${_redirect}`
  }

  const logout = async () => {
    try {
      const response = await $fetch(`${api}/auth/logout` as '/api/auth/logout', {
        method: 'GET',
        credentials: 'include'
      })
      $auth.value = { isAuthenticated: false, user: null }
      if (response.logoutUrl) {
        window.location.href = response.logoutUrl
      } else {
        navigateTo('/')
      }
    } catch (error) {
      console.error('Logout error:', error)
    }
  }

  const checkAuth = async () => {
    if (import.meta.server) {
      return $auth.value.isAuthenticated
    }

    try {
      isChecking.value = true
      const response = await $fetch(`${api}/auth/user` as '/api/auth/user', {
        method: 'GET',
        credentials: 'include'
      })

      if (!response) {
        $auth.value = { isAuthenticated: false, user: null }
        return false
      } else {
        $auth.value = { isAuthenticated: true, user: response }
        return true
      }
    } catch (error) {
      console.error('Token verification failed:', error)
      $auth.value = { isAuthenticated: false, user: null }
      return false
    } finally {
      isChecking.value = false
    }
  }

  const handleCallback = async (code: string) => {
    try {
      const response = await $fetch(`${api}/auth/callback` as '/api/auth/callback', {
        method: 'GET',
        credentials: 'include',
        params: {
          code: code
        }
      })
      $auth.value = { isAuthenticated: true, user: response.user }
      isChecking.value = false
    } catch (error) {
      console.error('Callback error:', error)
    }
  }

  return {
    isAuthenticated: computed(() => $auth.value.isAuthenticated),
    user: computed(() => $auth.value.user as User),
    login,
    logout,
    checkAuth,
    handleCallback,
    isChecking
  }
}
